import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function ReporteCortez() {
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroTienda, setFiltroTienda] = useState("");
  const [tiendas, setTiendas] = useState([]); // Debes llenar esto con datos reales
  const [datosAPI, setDatosAPI] = useState(null);

  // Función para obtener datos desde la API
  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"ReporteCierres", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}` // Se puede agregar filtroTexto y filtroTienda si es necesario
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        if (data && data.datos_Generados) {
          try {
            const datosGeneradosLimpiados = data.datos_Generados.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
            const datosGeneradosDecodificados = JSON.parse(datosGeneradosLimpiados);
            setDatosAPI(datosGeneradosDecodificados);
          } catch (error) {
            console.error("Error al decodificar los datos generados:", error);
          }
        } else {
          console.error("No se recibieron datos generados válidos:", data);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  const handleSearch = () => {
    fetchData();
  };

  // Función para exportar a Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datosAPI);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Cortez");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

    saveAs(data, 'ReporteCortez.xlsx');
  };

  // Función para exportar a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [['Fecha', 'Tienda', 'Serie', 'Efectivo', 'Tarjeta', 'Transferencia', 'Cheque']],
      body: datosAPI.map(row => [
        new Date(row.DocDate).toLocaleDateString(),
        row.Establecimiento, // Aquí se mantiene el valor de "Establecimiento" ya que en la API así se denomina
        row.Series,
        row.Efectivo,
        row.Tarjeta,
        row.Transferencia,
        row.Cheque
      ]),
    });

    doc.save('ReporteCortez.pdf');
  };

  return (
    <Container>
      <h2>Corte Z</h2>
      <hr />
      <Grid container spacing={2} alignItems="center" direction="row" wrap="nowrap">
        <Grid item xs={3}>
          <TextField
            label="Buscar"
            value={filtroTexto}
            onChange={(e) => setFiltroTexto(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="tienda-select-label">Tienda</InputLabel>
            <Select
              labelId="tienda-select-label"
              value={filtroTienda}
              onChange={(e) => setFiltroTienda(e.target.value)}
            >
              <MenuItem value="">Todas las tiendas</MenuItem>
              {tiendas.map((tienda, index) => (
                <MenuItem key={index} value={tienda}>
                  {tienda}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Fecha Inicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Fecha Fin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
            Buscar
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="warning" onClick={exportToPDF} fullWidth>
            PDF
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="success" onClick={exportToExcel} fullWidth>
            Excel
          </Button>
        </Grid>
      </Grid>

      <div style={{ marginTop: '20px' }}>
        {datosAPI && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Tienda</TableCell>
                  <TableCell>Serie</TableCell>
                  <TableCell>Efectivo</TableCell>
                  <TableCell>Tarjeta</TableCell>
                  <TableCell>Transferencia</TableCell>
                  <TableCell>Cheque</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datosAPI.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{new Date(row.DocDate).toLocaleDateString()}</TableCell>
                    <TableCell>{row.Establecimiento}</TableCell> {/* Mantenido como está para mostrar el nombre real desde la API */}
                    <TableCell>{row.Series}</TableCell>
                    <TableCell>{row.Efectivo}</TableCell>
                    <TableCell>{row.Tarjeta}</TableCell>
                    <TableCell>{row.Transferencia}</TableCell>
                    <TableCell>{row.Cheque}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Container>
  );
}

export default ReporteCortez;
