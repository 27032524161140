import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import { TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination } from '@mui/material'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";

function Abonoaut() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(10); 
  const [show, setShow] = useState(false); 
  const [resultado, setResultado] = useState(null);
  const [error, setError] = useState(null);
  const [codigoSeleccionado, setCodigoSeleccionado] = useState(null);
  const handleClose = () => setShow(false);
  const { token, user } = useSelector(state => state.auth);  


  const handleShow = (codigo) => {
    setCodigoSeleccionado(codigo);
    setShow(true);
  };
  
  const handleAccept = async() => {
    console.log('Código:', codigoSeleccionado); 
    const idusuarioenvia = user.id_usuario 

    console.log(idusuarioenvia)
    // Aquí puedes manejar la petición usando el `codigoSeleccionado` 
    const token = "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY";
    const codigoUsuario = "2165";
    const userNickName = "pierril";
    const metodo = "AutorizarAbono";
    const datosRecibidos = [{ acov_tipo: "2", acov_codigo: codigoSeleccionado, acov_usuario: idusuarioenvia }];
    
    const data = {
      Token: token,
      User: {
        Codigo: codigoUsuario,
        UserNickName: userNickName
      },
      Metodo: {
        NombreMetodo: metodo,
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    }; 

    console.log(data)

    try {
      const response = await fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const resultadoAPI = await response.json();
      setResultado(resultadoAPI);
      console.log('Respuesta de la API:', resultadoAPI);
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(error.message);
    }

    handleClose();
  };
  



  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaAbonos",
        Datos_Recibidos: `{"FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`; 

    fetch(fetchDataUrl)
    .then(response => response.json())
    .then(data => {
      if (data && data.datos_Generados) {
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        if (datosGeneradosDecodificados && datosGeneradosDecodificados.LISTABONO) {
          setDatosGenerados(datosGeneradosDecodificados.LISTABONO); 
        } else {
          setDatosGenerados([]);
        }
      } else {
        setDatosGenerados([]);
      }
    })
    .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);


  


  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const filteredData = datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    return (
      item.OV.toLowerCase().includes(searchValue) ||
      item.Banco.toLowerCase().includes(searchValue) ||
      item.FormaPago.toLowerCase().includes(searchValue)
    );
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  const descargarImagen = (base64, extension, nombreArchivo) => {
    // Crear el string base64 completo
    const imagenBase64Completa = `data:image/${extension};base64,${base64}`;
    
    // Crear un enlace temporal para descargar la imagen
    const enlace = document.createElement('a');
    enlace.href = imagenBase64Completa;
    enlace.download = `${nombreArchivo}.${extension}`;
    enlace.click();
  };
  const enviarPeticion = async () => {
    const token = "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY";
    const codigoUsuario = "2165";
    const userNickName = "pierril";
    const metodo = "AutorizarAbono";
    const datosRecibidos = [{ acov_tipo: "1", acov_codigo: "1", acov_usuario: "2" }];
    
    const data = {
      Token: token,
      User: {
        Codigo: codigoUsuario,
        UserNickName: userNickName
      },
      Metodo: {
        NombreMetodo: metodo,
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    };

    try {
      const response = await fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const resultadoAPI = await response.json();
      setResultado(resultadoAPI);
      console.log('Respuesta de la API:', resultadoAPI);
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(error.message);
    }
  };
  return (
    <div> 

      <br />
      <br /> 
      <h1>Autorizar Abono</h1>
      <div style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '10px', width: '500px' }}
        />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" onClick={handleSearch} style={{ height: '55px', width: '100px' }}>
          Buscar
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código Abono</TableCell>
            <TableCell>Tipo de Abono</TableCell>
            <TableCell>Banco</TableCell>
            <TableCell>Forma de Pago</TableCell>
            <TableCell>Orden de Venta</TableCell>
            <TableCell>Número de Referencia</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Proceso</TableCell>
            <TableCell>Autorizar</TableCell>
            <TableCell>Descargar Imagen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map(item => (
            <TableRow key={item.acov_codigo}>
              <TableCell>{item.acov_codigo}</TableCell>
              <TableCell>{item.TipoAbono}</TableCell>
              <TableCell>{item.Banco}</TableCell>
              <TableCell>{item.FormaPago}</TableCell>
              <TableCell>{item.OV}</TableCell>
              <TableCell>{item.NoReferencia}</TableCell>
              <TableCell>{item.Monto}</TableCell>
              <TableCell>
                {new Date(item.Fecha).toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </TableCell>
              <TableCell>{item.Proceso}</TableCell>
              <TableCell>
              <Button variant="primary" onClick={() => handleShow(item.acov_codigo)}>
                  <FontAwesomeIcon icon={faEdit} />
              </Button>
              </TableCell>
              <TableCell>
                {item.B64Imagen && item.B64Extension ? (
                  <Button
                    variant="contained"
                    onClick={() => descargarImagen(item.B64Imagen, item.B64Extension, `imagen_abono_${item.acov_codigo}`)}
                    startIcon={<FontAwesomeIcon icon={faDownload} />}
                  >
                    Descargar
                  </Button>
                ) : (
                  'Sin Imagen'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
       {/* Modal */}
       <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de Acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Desea continuar con la acción?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAccept}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </div>
  );
}

export default Abonoaut;
