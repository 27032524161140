import React from 'react'
import { Routes, Route } from "react-router-dom";

import { DatoScreen } from '../componentes/view/sala_venta/informacion_venta_cliente/DatoScreen';
import SalesExperienceClient from '../componentes/view/sala_venta/informacion_venta_cliente/SalesExperienceClient'; 
import HeladosPage from '../componentes/view/sala_venta/helados/create_day'; 
import CrearHelado from '../componentes/view/sala_venta/helados/create_ice_cream'; 
import AdminTiendasHelados from '../componentes/view/sala_venta/helados/admin';
import SaleProdcutsDay from '../componentes/view/sala_venta/reportes/rproductsforday';
import ProductForDate from '../componentes/view/sala_venta/reportes/productus_date';
import InvoiceForProductDay from '../componentes/view/sala_venta/reportes/invocieForProductDay';
import ReporteCajaConAPI from '../componentes/view/sala_venta/reportes/boxreportgeneral';
import ReporteCortez from '../componentes/view/sala_venta/reportes/cortez';
import GeneralSales from '../componentes/view/sala_venta/reportes/generalSales';

export const SalaVentaRoutes = () => {

  return (
    <Routes>
      <Route path="/dato_cliente_domicilio" element={ <DatoScreen /> } />
      <Route path="/datosClientes" element={ <SalesExperienceClient /> } /> 
      <Route path="/Heladosdeldia" element={ <HeladosPage /> } /> 
      <Route path="/creacion" element={ <CrearHelado /> } /> 
      <Route path="/MantTiendaMaquinaTolba" element={ <AdminTiendasHelados /> } /> 
      <Route path="/SaleProductsDay" element={ <SaleProdcutsDay /> } /> 
      <Route path="/SaleProductsDate" element={ <ProductForDate /> } /> 
      <Route path="/invoicesaleproduct" element={ <InvoiceForProductDay /> } />
      <Route path="/cortez" element={ <ReporteCortez /> } />
      <Route path="/Salebox" element={ <ReporteCajaConAPI /> } /> 
      <Route path="/GeneralSales" element={ <GeneralSales /> } />
    </Routes>
  )
}
