import React, { useEffect, useState } from 'react';
import { Container, Table, TableHead, TableBody, TableCell, TableRow, Button, TextField, Select, MenuItem, Grid } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

function ReporteCajaConAPI() {
  const [datosReporte, setDatosReporte] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroCaja, setFiltroCaja] = useState(""); // Estado para el ComboBox de filtro de caja
  const [opcionesCaja, setOpcionesCaja] = useState([]); // Opciones dinámicas del ComboBox
  const [filtroTexto, setFiltroTexto] = useState(""); // Estado para el filtro de texto

  // Función para obtener datos del reporte desde la API
  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"ReporteVentas", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        if (data && data.datos_Generados) {
          try {
            const datosGeneradosLimpiados = data.datos_Generados.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
            const datosGeneradosDecodificados = JSON.parse(datosGeneradosLimpiados);

            const reporteAgrupado = datosGeneradosDecodificados.reduce((acc, item) => {
              const caja = item.Caja;
              const detalle = JSON.parse(item.Detalle).DETALLE;

              if (!acc[caja]) {
                acc[caja] = {
                  Caja: caja,
                  Fecha: item.DocDate,
                  Productos: [],
                  MontoTotal: 0,
                  UnidadesTotal: 0
                };
              }

              detalle.forEach(producto => {
                const costoProducto = parseFloat(producto.DocTotal || 0);
                const unidades = parseFloat(producto.Cantidad || 0);

                if (costoProducto > 0) {
                  acc[caja].MontoTotal += costoProducto;
                  acc[caja].UnidadesTotal += unidades;

                  acc[caja].Productos.push({
                    Producto: producto.Dscription,
                    Unidades: unidades,
                    Monto: costoProducto
                  });
                }
              });

              return acc;
            }, {});

            const datosFormateados = Object.values(reporteAgrupado).map(cajaInfo => ({
              Caja: cajaInfo.Caja,
              Fecha: cajaInfo.Fecha,
              UnidadesTotal: cajaInfo.UnidadesTotal,
              MontoTotal: cajaInfo.MontoTotal.toFixed(2),
              Productos: cajaInfo.Productos
            }));

            setDatosReporte(datosFormateados);

            // Actualizamos las opciones del ComboBox con las cajas disponibles
            const opcionesUnicas = [...new Set(datosFormateados.map(caja => caja.Caja))];
            setOpcionesCaja(opcionesUnicas);
          } catch (error) {
            console.error("Error al decodificar los datos generados:", error);
          }
        } else {
          console.error("No se recibieron datos generados válidos:", data);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("REPORTE DE CAJA", 20, 10);
    doc.autoTable({
      head: [['Caja', 'Fecha', 'Unidades Totales', 'Monto Total']],
      body: datosReporte.map(row => [row.Caja, row.Fecha, row.UnidadesTotal, row.MontoTotal])
    });

    datosReporte.forEach(row => {
      doc.text(`Caja: ${row.Caja}`, 20, doc.lastAutoTable.finalY + 10);
      doc.autoTable({
        head: [['Producto', 'Unidades', 'Monto']],
        body: row.Productos.map(producto => [producto.Producto, producto.Unidades, producto.Monto.toFixed(2)]),
        startY: doc.lastAutoTable.finalY + 15
      });
    });

    doc.save('reporte-caja.pdf');
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datosReporte);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReporteCaja");
    XLSX.writeFile(workbook, 'reporte-caja.xlsx');
  };

  // Filtrar los datos por caja seleccionada en el ComboBox
  const datosFiltrados = datosReporte.filter(row => filtroCaja === "" || row.Caja === filtroCaja);

  return (
    <Container>
      <h2>Clientes Atendidos</h2>
      <hr />
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={2}>
          <TextField
            id="filtroTexto"
            label="Buscar"
            value={filtroTexto}
            onChange={(e) => setFiltroTexto(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            label="Caja"
            value={filtroCaja}
            onChange={(e) => setFiltroCaja(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Todas las cajas</MenuItem>
            {opcionesCaja.map((caja, index) => (
              <MenuItem key={index} value={caja}>{caja}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaInicio"
            label="Fecha de Inicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaFin"
            label="Fecha de Fin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
       
            <Button variant="contained" onClick={handleSearch}>
              Buscar
            </Button>
            <Button variant="contained" onClick={exportToPDF} style={{ marginLeft: "4px", marginRight: "4px" }} color='warning'>
               PDF
            </Button>
            <Button variant="contained" onClick={exportToExcel} color='success'>
               Excel
            </Button>
        
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Caja</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Unidades Totales</TableCell>
            <TableCell>Monto Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosFiltrados.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.Caja}</TableCell>
              <TableCell>{row.Fecha}</TableCell>
              <TableCell>{row.UnidadesTotal}</TableCell>
              <TableCell>{row.MontoTotal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default ReporteCajaConAPI;
